import styled, { css } from "styled-components";
import { BP, Font, Rem } from "../../commons/Theme";

export const StyledEmailFeedback = styled.div`
  position: relative;
  padding-top: ${Rem(100)};
`;

export const StyledEmailFeedbackTitle = styled.h1`
  display:inline;
  font-size: ${Rem(55)};
  margin-bottom: ${Rem(30)};
  font-family: ${Font.babas};
  @media (${BP.ipad}) {
    font-size: ${Rem(85)};
    margin-bottom: ${Rem(35)};
  }
`;

export const StyledEmailFeedbackSubtitle = styled.h2`
  font-size: ${Rem(22)};
  margin-bottom: ${Rem(50)};
  
  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    margin-bottom: ${Rem(100)};
  }
`;

export const StyledEmailFeedbackMarks = styled.div`
  display: flex;
  gap: ${Rem(16)};
  justify-content: center;
`;

export const StyledEmailFeedbackMark = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Rem(10)};

  font-size: ${Rem(35)};
  
  @media (${BP.tablet}) {
    font-size: ${Rem(55)};
  }
  
  @media (${BP.ipad}) {
    font-size: ${Rem(65)};
  }
`;

export const StyledEmailFeedbackMarkEmoji = styled.span`
  cursor: pointer;
  
  ${(props) =>
    props.isHidden
    && css`
      visibility: hidden;
    `}
`;
